import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "score": 1,
  "featured": false,
  "tileImage": "./tile-image-01.png",
  "tileTextColorScheme": "light",
  "types": ["art"],
  "status": ["completed"],
  "title": "Pengetræet",
  "subtitle": "Technical assistant for the art project by Johanne Aarup",
  "description": "",
  "years": ["2018"],
  "institutions": ["nb"],
  "location": "cph",
  "keywords": {
    "topics": ["art", "capitalism"],
    "tools": ["rpi", "flask", "python", "phillips hue"]
  },
  "people": [{
    "name": "Niklas Buhl",
    "role": "Technical Assistant"
  }, {
    "name": "Johanne Aarup",
    "role": "Artist"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      